import React from 'react';
import { memberService } from '../common/auth';
import $ from 'jquery';

export default class ModMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delete: false,
      mods: [],
      selected: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onMeld = this.onMeld.bind(this);
    this.showDelete = this.showDelete.bind(this);
  }

  onChange(name) {
    if (!this.state.delete && this.state.selected !== name) {
      this.props.onChange(name);
    }
  }

  onCreate(e) {
    this.setState({ delete: false });
    this.props.onCreate(e);
  }

  onDelete(e, name) {
    e.stopPropagation();
    this.setState({ delete: false });
    this.props.onDelete(name);
  }

  onMeld(e, name) {
    e.stopPropagation();
    this.props.onMeld(name);
  }

  onCheck(e, name) {
    e.stopPropagation();
    this.props.onCheck(name);
  }

  showDelete() {
    this.setState({ delete: true });
    const delay = 5000; // 5 seconds
    setTimeout(function() {
      this.setState({ delete: false });
    }.bind(this), delay);
  }

  componentDidMount() {
    // Capture event to not toggle dropdown on mod button click.
    $('#delmod').on("click.bs.dropdown", function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.showDelete(e);
    }.bind(this));
    // Load mods from member profile.
    const loadMods = () => {
      const member = memberService.member();
      if (member) {
        const mods = member['mods'] || [];
        let selected = member['selmod'];
        if (!selected && mods) {
          selected = mods[0];
        }
        this.setState({ mods: mods, selected: selected });
      } else { 
        this.setState({ mods: [], selected: undefined });
      }
    }
    loadMods();
    memberService.addCallback(loadMods.bind(this));
  }

  render() {
    const items = [];
    for (const [, m] of this.state.mods.entries()) {
      let cn = 'dropdown-item';
      if (m === this.state.selected) {
        cn += ' active';
      }
      const btns = [];
      if (m === this.state.selected) {
        btns.push(<button className="mod btn btn-success float-right ml-1" disabled={this.state.delete} value={m} onClick={(e) => this.onMeld(e, m)}>❱❱</button>);
        btns.push(<button className="mod btn btn-secondary float-right" disabled={this.state.delete} value={m} onClick={(e) => this.onCheck(e, m)}>✔</button>);
      } else if (this.state.delete) {
        btns.push(<button className="mod btn btn-danger float-right" value={m} onClick={(e) => this.onDelete(e, m)}>✖</button>);
      }
      items.push(
        <div className={cn} onClick={() => this.onChange(m)}>
          <span className="mr-2">{m}</span>
          {btns}
        </div>
      );
    }
    return (
      <div>
        <h6 class="dropdown-header">
          mods
          <button id="delmod" class="mod head btn btn-danger float-right ml-1" disabled={this.state.delete} onClick={this.showDelete}>✖</button>
          <button class="mod head btn btn-primary float-right" disabled={this.state.delete} onClick={this.onCreate}>✚</button>
        </h6>
        {items}
      </div>
    );
  }
}
  
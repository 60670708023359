import { mungService } from './api';
import { firebaseAuth, firestoreDB } from './firebase';

export const memberService = {
  addCallback,
  hasAuth,
  logout,
  member,
  onLogin,
  storeMember
};

const authCallbacks = [];

function addCallback(f) {
  authCallbacks.push(f);
}

function callback(member) {
  for (const [, f] of authCallbacks.entries()) {
    f(member);
  }
}

function hasAuth() {
  return sessionStorage.getItem('member') ? true : false;
}

function logout() {
  firebaseAuth.signOut().then(() => {
    sessionStorage.clear();
    callback();
  });
}

function member() {
  const js = sessionStorage.getItem('member');
  if (!js || js === 'undefined') {
    return {};
  }
  return JSON.parse(js);
}

function onLogin(uid, email) {
  return new Promise((resolve, reject) => {
    const dr = firestoreDB.collection('member').doc(uid);
    dr.get()
      .then((doc) => {
        if (doc.exists) {
          const member = doc.data();
          storeMember(member);
          // Register callback for member updates.
          dr.onSnapshot(doc => storeMember(doc.data()), err => {
            throw new Error('on snapshot: ' + err);
          });
          // Load selected mod.
          const mod = member['selmod'];
          if (mod) {
            return mungService.getMod(mod)
              .then(mod => mungService.getMung(mod['roots'][0], mod['name']),
                err => reject(new Error('getting mod: ' + err)))
              .then(resolve, err => reject(new Error('getting mung: ' + err)));
          }
          resolve();
        } else {
          return reject(new Error('member does not exist'));
        }
      })
      .catch((err) => {
        return reject(new Error('getting member: ' + err));
      });
  });
}

function storeMember(member) {
  sessionStorage.setItem('member', JSON.stringify(member));
  callback(member);
}

import React from 'react';
import ModMenu from './ModMenu';
import MungFlair from './MungFlair';
import TextFlair from '../common/TextFlair';

export default class MungMenu extends React.Component {
  render() {
    return (
      <div>
        <div className="mungmenu input-group border-bottom p-2">
          <div className="input-group-prepend">
            <button type="button" className="btn btn-warning" onClick={this.props.tryMung}>try</button>
            <button type="button" className="btn btn-warning dropdown-toggle dropdown-toggle-split border-secondary border-top-0 border-right-0 border-bottom-0" 
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="sr-only">toggle actions</span>
            </button>
            <div className="dropdown-menu">
              <ModMenu onChange={this.props.changeMod} onCreate={this.props.createMod} onDelete={this.props.deleteMod} 
                onCheck={this.props.checkMod} onMeld={this.props.meldMod} />
              <h6 class="dropdown-header">dev</h6>
              <div className="dropdown-item" onClick={this.props.resetMung}>reset mung</div>
              <div role="separator" className="dropdown-divider"></div>
              <div className="dropdown-item" data-toggle="collapse" data-target="#mungFlair">
                <TextFlair text='choose flair' />
              </div>
            </div>
          </div>
          <input type="text" className="form-control border-warning" placeholder="search" aria-label="search" />
          <div className="input-group-append">
            <button type="button" className="btn btn-warning">
              <i className="material-icons-outlined md-18 align-middle">search</i>
            </button>
          </div>
        </div>
        <MungFlair bgs={this.props.flairBGs} fgs={this.props.flairFGs} onClick={this.props.flairClick} />
      </div>
    );
  }

}
  
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Nav from './Nav';
import Learn from '../learn';
import Build from '../build';
import Share from '../share';
import Settings from '../settings';
import Login from './Login';
import $ from 'jquery';

export default class App extends React.Component {
  componentDidMount() {
    // Enable tooltips
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' })
    });
  }

  render() {
    return (
      <div>
        <Router>
          <Nav />
          <main>
            <Switch>
              <Redirect exact from='/' to='/build' />
              <PrivateRoute exact path={"/learn"} component={Learn} />
              <PrivateRoute exact path={"/build"} component={Build} />
              <PrivateRoute exact path={"/share"} component={Share} />
              <PrivateRoute exact path={"/settings"} component={Settings} />
              <Route exact path={"/login"} component={Login} />
            </Switch>
          </main>
      </Router>
      </div>
    );
  }
}

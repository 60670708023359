import React from 'react';

export default class MungOp extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.type !== '') {
      this.onClick = this.props.onClick.bind(this);
      this.hideBadges = this.props.hideBadges.bind(this);
      this.showBadges = this.props.showBadges.bind(this);
    }
    this.onBadgeClick = this.onBadgeClick.bind(this);
  }

  onBadgeClick(e) {
    if (this.props.selected) {
      this.props.onBadgeClick(e);
    }
  }

  render() {
    // TODO(greg): give more thought to short name generation.
    const name = this.props.name;
    const display = this.props.display;
    const short = display ? display : name ? name.substring(0, 4) : '';
    const flair = this.props.flair + ' ';
    const color = this.props.colIdx ? 'col' + this.props.colIdx + ' ' : '';
    // TODO(greg): validate input
    let badge;
    if (this.props.badge > 0 && (this.props.showBadge || this.props.selected)) {
      badge = (
        <a href="#" class="badge badge-dark" onClick={this.onBadgeClick}>
          {this.props.badge}
          <span class="sr-only">info items</span>
        </a>
      );
    }
    let cn, lab, inner, type;
    if (this.props.valType && (this.props.showBadge || this.props.selected)) {
      type = (
        <div class="type badge badge-light">
          {this.props.valType}
        </div>
      );
    }
    switch (this.props.type) {
      case '':
        lab = (<div className="space"></div>);
        break;
      case 'in':
        cn = 'header-l';
        lab = (
          <label className={'mungin fg btn mt-0 mb-0 ' + flair + color} onMouseEnter={this.showBadges} onMouseLeave={this.hideBadges}>
            <input className="d-none" type="radio" name="mungop" value={this.props.id} 
              onClick={this.onClick} />
            <div className='name'>{short}</div>
            {type}
            {badge}
          </label>
        );
        break;
      case 'op':
        inner = (<div className="w-100 text-center">{short}</div>);
        if (this.props.icon) {
          inner = (<i className="material-icons-outlined md-48 align-middle">{this.props.icon}</i>);
        }
        lab = (
          <label className={'mungop fg text-center p-2 mt-0 mb-0 ' + flair + color} onMouseEnter={this.showBadges} onMouseLeave={this.hideBadges} >
            <input className="d-none" type="radio" name="mungop" value={this.props.id} onClick={this.onClick} />
            {badge}
            {inner}
          </label>
        );
        break;
      case 'out':
        cn = 'header-r'
        lab = (
          <label className={'mungout fg btn mt-0 mb-0 ' + flair + color} onMouseEnter={this.showBadges} onMouseLeave={this.hideBadges} >
            <input className="d-none" type="radio" name="mungop" value={this.props.id} onClick={this.onClick} /> 
            <div className="name">{short}</div>
            {type}
            {badge}
          </label>
        );
        break;
      default:
        lab = 'invalid type ' + this.props.type
    }
    let wc = "opwrap";
    if (this.props.selected) {
      wc = wc + " selected"
    }
    // TODO(greg): display type on mouse over e.g. tooltip.
    return (
      <td className={cn}>
        <div className={wc} id={this.props.id}>
          {lab}
        </div>
      </td>
    );
  }

}
import React from 'react';
import UnitDeck from './UnitDeck';

export default class Course extends React.Component {
  constructor(props) {
    super(props);
    this.onUnitClick = this.props.onUnitClick.bind(this);
    this.onLessonClick = this.props.onLessonClick.bind(this);
  }

  render() {
    var course = this.props.course;
    let icon = "school";
    if (course.icon) {
      icon = course.icon;
    }
    return (
      <div>
        <div className="coursetitle alert alert-info sticky-top mb-0">
          <i className="material-icons-outlined md-24 align-middle">{icon}</i>
          <span className="ml-2 align-middle"><b>{course.name}</b>: {course.desc}</span>
        </div>
        <UnitDeck units={course.units} onClick={this.onUnitClick} onLessonClick={this.onLessonClick} />
      </div>
    );
  }
}

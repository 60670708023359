import React from 'react';
import UnitCard from './UnitCard';

export default class UnitDeck extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.props.onClick.bind(this);
    this.onLessonClick = this.props.onLessonClick.bind(this);
  }

  render() {
    var units = this.props.units
    if (!units) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    console.info('unitdeck: ' + JSON.stringify(units))
    const items = [];
    for (const [i, u] of units.entries()) {
      let done = 0;
      for (const [i, l] of u.lessons.entries()) {
        if (l.done) {
          done++;
        }
      }
      items.push(
        <UnitCard key={i} id={u.name} title={u.title} desc={u.desc} lessons={u.lessons} 
          done={done} locked={u.locked} onClick={this.onClick} onLessonClick={this.onLessonClick} />
      );
    }
    return (
      <div id="unitdeck" className="p-2">
        {items}
      </div>
    );
  }
}

import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default class MungletForm extends React.Component {
  constructor(props) {
    super(props);
    this.id = 'form-' + uuidv4();
    this.state = {
      init: false,
      input: {},
      lock: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    if (this.props.onClose) {
      this.onClose = this.props.onClose.bind(this);
    }
    if (this.props.onSubmit) {
      this.onSubmit = this.props.onSubmit.bind(this);
    }
  }

  handleChange(e) {
    const name = e.target.name;
    let value = e.target.value;
    // Store button value if specified.
    if (e.target.type === 'submit') {
      if (String(value) !== '') {
        this.setState({ target: value });
      }
      return
    }
    // Correctly handle numeric values, i.e. not a string.
    if (e.target.type === 'number') {
      value = Number(e.target.value)
    }
    const { input } = { ...this.state };
    const currentState = input;
    currentState[name] = value;
    this.setState({ input: currentState });
    console.error('handleChange.state: ' + JSON.stringify(this.state))
  }

  handleSubmit(e) {
    e.preventDefault();
    // Clear previous results.
    this.setState({ 
      error: undefined,
      output: undefined,
      lock: true
    })
    const target = this.state.target + '';
    if (target.startsWith('MUNG:')) {
      const req = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: uuidv4(),
          dst: this.state.target,
          in: this.state.input
        })
      };
      console.error('handleSubmit.req: ' + JSON.stringify(req));
      return fetch(`/mung/call`, req)
        .then(this.handleResponse);
    }
    if (this.props.onSubmit) {
      return this.onSubmit({
          dst: this.state.target,
          in: this.state.input,
          e: e
        })
        .catch(err => {
          console.error(err)
          this.setState({ error: err, lock: false })
        });
    }
    if (this.state.target === undefined) {
      this.setState({ error: new Error('no target specified'), lock: false })
    }
  }

  handleReset() {
    this.setState({ 
      error: undefined,
      output: undefined,
      input: {},
      init: false
    }, () => this.initInputs);
  }

  handleResponse(r) {
    return r.text().then(text => {
      console.error('handleResponse.resp: ' + text)
      if (!r.ok) {
        this.setState({ error: new Error(text), lock: false })
        return
      }
      const obj = JSON.parse(text)
      console.error('handleResponse.data: ' + JSON.stringify(obj.out))
      this.setState({ output: obj.out, lock: false })
      return obj;
    })
  }

  parseElement(elem) {
    // Element string:
    // TYPE[name][map|list](value){json}
    //   map: [key:value,...]
    //   list: [value,...]
    const elemRE = /^([A-Z](?:[_-]?[A-Z0-9]+)*)(?:\[([a-z](?:[_-]?[a-z0-9]+)*)])?(?:\[(?:((?:[a-z](?:[_-]?[a-z0-9]+)*):.+?(?:,[a-z](?:[_-]?[a-z0-9]+)*:.+?)*)|(.+?(?:,.+?)*))\])?(?:\((.+?)\))?(?:\{(.+?)\})?/;
    const match = elem.match(elemRE);
    if (match) {
      let map = {}
      if (match[3]) {
        const items = match[3].split(',')
        for (const i of items) {
          const parts = i.split(':', 2)
          map[parts[0]] = parts[1]
        }
      }
      let list = []
      if (match[4]) {
        list = match[4].split(',')
      }
      let js
      if (match[6]) {
        js = JSON.parse(match[6])
      }
      return {
        match: match[0],
        type: match[1].toLowerCase(),
        name: match[2],
        value: match[5],
        map: map,
        list: list,
        json: js
      }
    }
    return {
      type: '',
      value: elem,
      map: {},
      list: [],
    }
  }

  renderElement(elem) {
    // TODO(greg): add validation for various types.
    const readonly = elem['list'].includes('readonly')
    if (!elem['name']) {
      elem['name'] = elem['type']
    }
    switch (elem['type']) {
      case 'button':
      case 'submit':
        return <button className="btn btn-primary form-control" type="submit" name={elem['name']} value={elem['value']} aria-label={elem['name']} onClick={this.handleChange}>{elem['name']}</button>;
      case 'check':
        return (
          <div className="input-group-text form-control">
            <input type="checkbox" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} aria-label={elem['name']} />
            <span className="ml-2">{elem['name']}</span> 
          </div>
        );
      case 'close':
        return <button className="btn btn-secondary form-control" type="button" name={elem['name']} aria-label={elem['name']} onClick={this.onClose}>{elem['name']}</button>;
      case 'color':
        return <input type="color" className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} aria-label={elem['name']}/>;
      case 'date':
        return <input type="date" className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']}/>;
      case 'datetime':
        return <input type="datetime-local" className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']}/>;
      case 'email':
        return <input type="email" className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']}/>;
      case 'file':
        return <input type="file" className="form-control-file" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} aria-label={elem['name']}/>;
      case 'hidden':
        return <input type="hidden" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']}/>;
      case 'html':
        return <div name={elem['name']} dangerouslySetInnerHTML={{__html: elem['value']}} />;
      case 'img':
        return <img alt={elem['name']} src={elem['value']} className="rounded w-100" />;
      case 'label':
        return <label className="input-group-text form-control" aria-label={elem['value']}>{elem['value']}</label>;
      case 'list':
        // TODO(greg): add form for list creation (i.e. instead of text box).
        return (
          <div className="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">[{elem['name']}]</span>
            </div>
            <input type="text" className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']} autoComplete='off' autoCapitalize='none' readOnly={readonly}/>
          </div>
        );
      case 'number':
        return <input type="number" className="form-control" onChange={this.handleChange} onLoad={this.handleShow} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']}/>;
      case 'radio':
        return (
          <div className="input-group-text form-control">
            <input type="radio" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} aria-label={elem['value']} />
            <span className="ml-2">{elem['value']}</span> 
          </div>
        );
      case 'reset':
        return <button className="btn btn-secondary form-control" type="reset" aria-label={elem['name']}>{elem['name']}</button>;
      case 'select':
        let opts = []
        opts.push(<option key="" value="" hidden>choose {elem['name']}...</option>);
        const map = elem['map']
        for (let k in map) {
          if (map.hasOwnProperty(k)) {    
            opts.push(<option key={k} value={map[k]}>{k}</option>);       
          }
        }
        return (
          <select className="form-control" defaultValue="" onChange={this.handleChange} name={elem['name']} placeholder={elem['name']} >
            {opts}
          </select>
        );
      case 'text':
        return <input type="text" className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']} autoComplete='off' autoCapitalize='none' readOnly={readonly}/>;
      case 'textarea':
        return <textarea className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']} readOnly={readonly}></textarea>;
      case 'time':
        return <input type="time" className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']}/>;
      case 'url':
        return <input type="url" className="form-control" onChange={this.handleChange} name={elem['name']} defaultValue={elem['value']} placeholder={elem['name']} aria-label={elem['name']}/>;
      default:
        return elem['value'];
    }
  }

  initInputs() {
    if (this.state.init) {
      return
    }
    this.setState({ init: true }, () => {
      let body = this.props.body
      for (let elems of body) {
        if (!Array.isArray(elems)) {
          elems = [elems]
        }
        for (let i = 0; i < elems.length; i++) {
          const elem = this.parseElement(elems[i])
          if (elem.name && elem.value) {
            this.handleChange({ target: elem })
          }
        }
      }
    })
  }

  componentDidUpdate(prevState, prevProps) {
    if (prevProps.body !== this.props.body) {
      this.initInputs()
    }
  }

  render() {
    if (this.props.body.length === 0 || this.state.closed) {
      return ''
    }
    const body = []
    // Display error before other elements.
    if (this.state.error) {
      let err = this.state.error.toString();
      err = err.replace(/\s/g, " ").replace(/["']/g, "'").replace(/[[({]/g, "<").replace(/[\])}]/g, ">");
      body.push('TEXTAREA[error][readonly](' + err + ')');
    }
    // Display output on form with label:value.
    if (this.state.output) {
      for (const [k, v] of Object.entries(this.state.output)) {
        body.push(['LABEL(' + k + ')', 'LABEL(' + v + ')']);
      }
      body.push(['CLOSE', 'RESET[back]']);
    } else {
      // Otherwise display configured elements.
      for (let elem of this.props.body) {
        body.push(elem)
      }
    }
    const groups = []
    for (let elems of body) {
      if (!Array.isArray(elems)) {
        elems = [elems]
      }
      const group = []
      for (let i = 0; i < elems.length; i++) {
        const elem = this.parseElement(elems[i])
        const inner = this.renderElement(elem)
        group.push(inner);
      }
      groups.push(
        <div className="form-group mb-2">
          <div className="input-group">
            {group}
          </div>
        </div>
      );
    }
    return (
      <form className="p-3" id={this.id} onSubmit={this.handleSubmit} onReset={this.handleReset}>
        <fieldset disabled={this.state.lock}>
          {groups}
        </fieldset>
      </form>
    );
  }
}
import React from 'react';

export default class Lesson extends React.Component {
  constructor(props) {
    super(props);
    this.onBackClick = this.props.onBackClick.bind(this);
  }

  render() {
    let icon = "school";
    if (this.props.icon) {
      icon = this.props.icon;
    }
    return (
      <div>
        <div className="coursetitle alert alert-warning sticky-top mb-0">
          <i className="material-icons-outlined md-24 align-middle">{icon}</i>
          <span className="ml-2 align-middle">{this.props.course} : {this.props.unit} : <b>{this.props.lesson}</b></span>
          <i className="lessonback material-icons-outlined md-24 float-right" onClick={this.onBackClick}>backspace</i>
        </div>
      </div>
    );
  }
}

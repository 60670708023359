import React from 'react';
import { NavLink } from 'react-router-dom';
import { memberService } from '../common/auth';

export default class Nav extends React.Component {
  componentDidMount() {
    memberService.addCallback(() => this.forceUpdate());
  }

  render() {
    const hasAuth = memberService.hasAuth()
    return (
      <nav className="navbar navbar-light fixed-top bg-light shadow">
        <a className="navbar-brand" href="#logo">
          <img src="/logo.svg" alt="Mungie" width="100px" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbar">
          <div className="btn-group btn-group-toggle pt-2" data-toggle="collapse" data-target="#navbar" >
            <NavLink to={'/learn'} activeClassName="active" className={"btn btn-outline-secondary btn-lg" + (hasAuth ? "" : " disabled")}>
                <input type="radio" name="nav" id="rate" autoComplete="off" style={{display: 'none'}} />
                <i className="material-icons-outlined md-36 align-middle">school</i>
            </NavLink>
            <NavLink to={'/build'} activeClassName="active" className={"btn btn-outline-secondary btn-lg" + (hasAuth ? "" : " disabled")}>
                <input type="radio" name="nav" id="follow" autoComplete="off" style={{display: 'none'}} />
                <i className="material-icons-outlined md-36 align-middle">hardware</i>{/*schema*/}
            </NavLink>
            <NavLink to={'/share'} activeClassName="active" className="btn btn-outline-secondary btn-lg disabled">
                <input type="radio" name="nav" id="public" autoComplete="off" style={{display: 'none'}} />
                <i className="material-icons-outlined md-36 align-middle">public</i>
            </NavLink>
            <NavLink to={'/settings'} activeClassName="active" className="btn btn-outline-secondary btn-lg disabled">
                <input type="radio" name="nav" id="settings" autoComplete="off" style={{display: 'none'}} />
                <i className="material-icons-outlined md-36 align-middle">settings</i>
            </NavLink>
            {/* TODO(greg): we should go back to login on exit, unless from login, then return to home */}
            {/* TODO(greg): also move log out under settings to open additional nav tap (for game-ification, etc.) */}
            <NavLink to={'/login'} activeClassName="" className="btn btn-outline-secondary btn-lg">
                <input type="radio" name="nav" id="login" autoComplete="off" style={{display: 'none'}} />
                <i className="material-icons-round md-36 align-middle">exit_to_app</i>
            </NavLink>
          </div>
        </div>
      </nav>
    );
  }
}
import React from 'react';
import MungRow from './MungRow';
import TextFlair from '../common/TextFlair';
import $ from 'jquery';

export default class MungBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      mode: "view",
      showBadge: false
    };
    this.hideBadges = this.hideBadges.bind(this);
    this.showBadges = this.showBadges.bind(this);
    this.onOpClick = this.props.onOpClick.bind(this);
  }

  focus(id) {
    $('.screen').removeClass('d-none')
    const obj = $('#' + id)
    obj.addClass('focus')
    obj[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  hideBadges() {
    this.setState({ showBadge: false });
  }

  showBadges() {
    this.setState({ showBadge: true });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
  }

  render() {
    const rows = this.props.rows
    const rowEnd = this.props.rowEnd
    let fg = 'rgyb';
    if (this.props.fg) {
      fg = this.props.fg;
    }
    let cn = 'bg ';
    if (this.props.bg) {
      cn = cn + this.props.bg;
    } else {
      cn = cn + 'argon';
    }
    if (!rows) {
      return (
        <div className="mungboard">
          <div className={cn}></div>
          <div className="d-flex justify-content-center mt-5">
            <TextFlair text='coding for everyone™' col1='#0088ff' col2='#3ce2ff' />
          </div>
        </div>
      );
    }
    console.info('mungboard: ' + JSON.stringify(rows))
    const items = [];
    for (const [, r] of rows.entries()) {
      const closeInfo = !((r.in in this.props.selected));
      const rowStart = r.in.group ? r.in.group : 0;
      const selected = this.props.selected[r.in.name];
      items.push(
        <MungRow in={r.in} out={r.out} ops={r.ops} start={rowStart} end={rowEnd} selected={selected}
          bg={this.props.bg} fg={fg} closeInfo={closeInfo} mode={this.state.mode}
          onOpClick={this.onOpClick} hideBadges={this.hideBadges} showBadges={this.showBadges}
          showBadge={this.state.showBadge} />
      );
    }
    return (
      <div className="mungboard">
        <div className='screen d-none'></div>
        <div className={cn}></div>
        <table>
          <tbody>
            {items}
          </tbody>
        </table>
      </div>
    )
  }
}

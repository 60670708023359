import React from 'react';
import LessonCard from './LessonCard';

export default class LessonDeck extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.props.onClick.bind(this);
  }

  // TODO(greg): see MungDeck for reference. 
  render() {
    var lessons = this.props.lessons || [];
    if (!lessons) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    console.info('lessondeck: ' + JSON.stringify(lessons))
    const items = [];
    for (const [i, l] of lessons.entries()) {
      items.push(
        <LessonCard key={i} id={l.name} icon={l.icon} done={l.done} onClick={this.onClick} />
      )
    }
    return (
      <div className="align-middle m-0">
        {items}
      </div>
    );
  }
}

import React from 'react';

export default class LessonCard extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.props.onClick.bind(this);
  }

  render() {
    let col = "light";
    if (this.props.done) {
      col = "primary";
    }
    return (
      <div className="d-inline-block">
        <label className={"btn btn-" + col + " p-2 m-1"} data-toggle="button" aria-pressed="false" autoComplete="off">
          <input className="d-none" type="radio" name="lessoncard" value={this.props.id} autoComplete="off" onClick={this.onClick}/>
          <i className="material-icons-outlined md-48 align-middle">{this.props.icon}</i>
          <p className="m-0">{this.props.id}</p>
        </label>
      </div>
    );
  }
}
  
import React from 'react';
import Course from './Course';
import Lesson from './Lesson';

export default class Learn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      course: "mung101",
      unit: undefined,
      lesson: undefined,
    };
    this.onLessonClick = this.onLessonClick.bind(this);
    this.onLessonBackClick = this.onLessonBackClick.bind(this);
    this.onUnitClick = this.onUnitClick.bind(this);
  }

  onLessonClick(e) {
    // TODO(greg): use lesson name to look up object.
    this.setState({ lesson: e.target.value });
  }

  onLessonBackClick() {
    // TODO(greg): ask before leaving lesson.
    this.setState({ lesson: undefined });
  }

  onUnitClick(e) {
    this.setState({ unit: e.target.value });
  }

  render() {
    var course = this.props.course;
    // TODO(greg): load course from somewhere.
    if (!course) {
      course = {
        name: "mung101",
        desc: "intro to mungie dev",
        icon: "school",
        units: [
          { name: "basics", title: "basics", desc: "learn the basics of a mung", done: 3, lessons: [
            { name: "inputs", icon: "arrow_forward_ios", done: true},
            { name: "outputs", icon: "arrow_back_ios", done: true},
            { name: "ops", icon: "add", done: true},
          ]},
          { name: "types", title: "types", desc: "learn about mung data dypes", lessons: [
            { name: "text", icon: "article", done: true},
            { name: "integer", icon: "looks_one", done: true},
            { name: "float", icon: "analytics"},
            { name: "boolean", icon: "check_box", done: true},
          ]},
          { name: "lists", title: "lists", desc: "learn how to manipulate lists of data", lessons: [
            { name: "create", icon: "list_alt", done: true},
            { name: "sort", icon: "sort"},
            { name: "filter", icon: "filter_list"},
            { name: "stats", icon: "format_list_numbered_rtl"},
          ]},
          { name: "loops", title: "loops", desc: "learn how to iterate over data", lessons: [
            { name: "iterate", icon: "cached"},
            { name: "tbd", icon: "loop"},
            { name: "tbd", icon: "published_with_changes"},
            { name: "tbd", icon: "settings_backup_restore"},
            { name: "tbd", icon: "switch_access_shortcut_add"},
            { name: "tbd", icon: "all_inclusive"},
          ]},
          { name: "logic", title: "logic", desc: "learn how to apply conditional logic", lessons: [
            { name: "tbd", icon: "offline_bolt"},
            { name: "tbd", icon: "query_builder"},
            { name: "tbd", icon: "outbound"},
            { name: "tbd", icon: "paid"},
            { name: "tbd", icon: "sentiment_satisfied"},
          ]},
          { name: "functions", title: "functions", lessons: [
            {}, {}, {}, {}, {}
          ], locked: true },
        ],
      };
    }
    let content = <Course course={course} onUnitClick={this.onUnitClick} onLessonClick={this.onLessonClick} />;
    if (this.state.lesson) {
      let icon;
      // TODO(greg): think of better way to store/extract this info.
      for (const [i, u] of course.units.entries()) {
        if (this.state.unit == u.name) {
          for (const [j, l] of u.lessons.entries()) {
            if (this.state.lesson == l.name) {
              icon = l.icon;
            }
          }
        }
      }
      content = <Lesson course={this.state.course} unit={this.state.unit} lesson={this.state.lesson} icon={icon} onBackClick={this.onLessonBackClick} />;
    }
    return (
      <div className='mungwrap d-flex flex-column'>
        {content}
      </div>
    );
  }
}

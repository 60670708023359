import React from 'react';

export default class MungCard extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.props.onClick.bind(this);
  }

  render() {
    return (
      <div>
        <label className="btn btn-outline-secondary p-2 m-1" data-toggle="button" aria-pressed="false" autoComplete="off">
          <input className="d-none" type="radio" name="mungcard" value={this.props.id} autoComplete="off" onClick={this.onClick} />
          <i className="material-icons-outlined md-48 align-middle">{this.props.icon}</i>
        </label>
      </div>
    );
  }

}
  
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { memberService } from '../common/auth';

const defaultRedirect = '/build';
const firebaseConfig = {
  apiKey: "AIzaSyC25Zg_CjFurUyHc4cgAAOSvE4G5LIR9WY",
  authDomain: "mungie-dev.firebaseapp.com",
  projectId: "mungie-dev",
  storageBucket: "mungie-dev.appspot.com",
  messagingSenderId: "176846364211",
  appId: "1:176846364211:web:d231e33c827116ef438d46"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firestoreDB = firebase.firestore(firebaseApp);
export const firebaseAuth = firebase.auth();

export const firebaseUIConfig = {
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      const user = authResult.user;
      const redirect = () => {
        const url = redirectUrl ? redirectUrl : defaultRedirect;
        window.location.replace(url);
      }
      memberService.onLogin(user.uid, user.email)
        .then(redirect, err => {
          throw err;
        });
      return false;
    },
  },
  signInFlow: 'popup',
  signInSuccessUrl: defaultRedirect,
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true,
        adminEmail: 'greg@mungie.com'
      }
    },
    // TODO(greg): configure other auth providers.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
  ],
  tosUrl: 'https://www.mungie.com/terms',
  privacyPolicyUrl: 'https://www.mungie.com/privacy',
};

import React from 'react';
import MungBoard from './MungBoard';
import MungDeck from './MungDeck';
import MungMenu from './MungMenu';
import MungletForm from '../stream/MungletForm';
import TextFlair from '../common/TextFlair';
import { mungService } from '../common/api';
import $ from 'jquery';

export default class Mung extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
      loading: false,
      selOp: {},
      // TODO(greg): load flair from user/device.
      bg: 'argon',
      fg: 'bgyr',
      bgs: ['argon', 'sunset', 'coder', 'none'],
      fgs: ['bgyr', 'rare', 'neon', 'mono'],
    }
    this.changeMod = this.changeMod.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.flairClick = this.flairClick.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onOpClick = this.onOpClick.bind(this);
    this.showAddBGFlair = this.showAddBGFlair.bind(this);
    this.showAddFGFlair = this.showAddFGFlair.bind(this);
    this.showAddInput = this.showAddInput.bind(this);
    this.showAddOp = this.showAddOp.bind(this);
    this.showAddOutput = this.showAddOutput.bind(this);
    this.showCheckMod = this.showCheckMod.bind(this);
    this.showCreateMod = this.showCreateMod.bind(this);
    this.showDeleteMod = this.showDeleteMod.bind(this);
    this.showMeldMod = this.showMeldMod.bind(this);
    this.showMung = this.showMung.bind(this);
    this.showResetMung = this.showResetMung.bind(this);
  }

  changeMod(name) {
    this.setState({ loading: true });
    mungService.changeMod(name);
  }

  clearSelected() {
    this.setState({ selOp: {} })
  }

  closeForm() {
    this.setState({ form: undefined })
  }

  handleCardClick(id) {
    console.log('clicked: ' + id)
    switch (id) {
      case 'FUN:core:in':
        this.showAddInput();
        break;
      case 'FUN:core:out':
        this.showAddOutput();
        break;
      default:
        const part = id.split(':');
        this.showAddOp(part[1], part[2]);
    }
  }

  handleFormSubmit(req) {
    console.error('form submitted to ' + req.dst + ' with ' + JSON.stringify(req.in));
    const clearSelected = this.clearSelected;
    const closeForm = this.closeForm;
    const selectedOps = Object.values(this.state.selOp);
    return new Promise(function(resolve, reject) {
      switch (req.dst) {
        case 'addbg':
          if (!req.in.name || req.in.name === '') {
            return reject(new Error('invalid bg: name must be specified'));
          }
          let bg = req.in.bg1;
          if (req.in.bg2) {
            bg = 'linear-gradient(0deg, ' + req.in.bg1 + ' 0%, ' + req.in.bg2 + ' 100%)';
          }
          this.loadBGFlair({ 
            name: req.in.name,
            bg: bg,
            track: req.in.track,
            arrow: req.in.arrow,
            op: req.in.op,
            op_fg: req.in.op_fg,
          });
          this.setState({ bg: req.in.name });
          closeForm();
          break;
        case 'addfg':
          if (!req.in.name || req.in.name === '') {
            return reject(new Error('invalid fg: name must be specified'));
          }
          this.loadFGFlair({
            name: req.in.name,
            ops: [
              { bg: req.in.bg1, fg: req.in.fg1 },
              { bg: req.in.bg2, fg: req.in.fg2 },
              { bg: req.in.bg3, fg: req.in.fg3 },
              { bg: req.in.bg4, fg: req.in.fg4 }
            ]
          });
          this.setState({ fg: req.in.name });
          closeForm();
          break;
        case 'addin':
          mungService.addInput(req.in.name, req.in.type)
            .then(() => {
              clearSelected();
              closeForm();
            })
            .catch(err => reject(err));
          break;
        case 'addmod':
          mungService.makeMod(req.in.name)
            .then(() => {
              closeForm();
            })
            .catch(err => reject(err));
          break;
        case 'addop':
          if (selectedOps.length === 0) {
            return reject(new Error('at least one input must be selected'));
          }
          mungService.addOp(selectedOps, req.in.id)
            .then(() => {
              clearSelected();
              closeForm();
            })
            .catch(err => reject(err));
          break;
        case 'addout':
          if (selectedOps.length !== 1) {
            return reject(new Error('exactly one input must be selected'));
          }
          const input = selectedOps[0].split(':')[0];
          mungService.addOutput(input, req.in.name)
            .then(() => {
              clearSelected();
              closeForm();
            })
            .catch(err => reject(err));
          break;
        case 'chkmod':
          return reject(new Error('check mod not implemented'));
        case 'delmod':
          mungService.nixMod(req.in.name)
            .then(() => {
              closeForm();
            })
            .catch(err => reject(err));
          break;
        case 'newmung':
          mungService.makeMung(req.in.name)
            .then(() => {
              closeForm();
            })
            .catch(err => reject(err));
          break;
        case 'meldmod':
          return reject(new Error('meld mod not implemented'));
        default:
          return reject(new Error('invalid form target: ' + req.dst));
      }
    }.bind(this));
  }

  onOpClick(input, e) {
    const name = e.target.value;
    const selected = this.state.selOp;
    if (selected[input] === name) {
      delete selected[input];
    } else {
      selected[input] = name;
    }
    this.setState({ selOp: selected });
    // Unfocus when op is cliked.
    $('.screen').addClass('d-none')
    console.error('ops selected: ' + Object.values(selected));
  }

  showAddBGFlair() {
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(add background flair)', 'TEXT[name]'],
          ['LABEL(bg)', 'COLOR[bg1](#55ddff)', 'LABEL(bg2)', 'COLOR[bg2](#dd55ff)'],
          ['LABEL(track)', 'COLOR[track](#55ddff)'],
          ['LABEL(arrow)', 'COLOR[arrow](#55ddff)'],
          ['LABEL(op)', 'COLOR[op](#55ddff)', 'LABEL(fg)', 'COLOR[op_fg]'],
          ['CLOSE', 'SUBMIT[add](addbg)']
        ]
      });
    });
  }

  showAddFGFlair() {
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(add foreground flair)', 'TEXT[name]'],
          ['LABEL(bg1)', 'COLOR[bg1](#007bff)', 'LABEL(fg1)', 'COLOR[fg1]'],
          ['LABEL(bg2)', 'COLOR[bg2](#28a745)', 'LABEL(fg2)', 'COLOR[fg2]'],
          ['LABEL(bg3)', 'COLOR[bg3](#ffc107)', 'LABEL(fg3)', 'COLOR[fg3]'],
          ['LABEL(bg4)', 'COLOR[bg4](#dc3545)', 'LABEL(fg4)', 'COLOR[fg4]'],
          ['CLOSE', 'SUBMIT[add](addfg)']
        ]
      });
    });
  }

  showAddInput() {
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(add input)', 'TEXT[name]'],
          ['LABEL(value type)', 'SELECT[type][integer:int,float:float,text:text,boolean:bool,list:list]'],
          ['CLOSE', 'SUBMIT[add](addin)']
        ]
      });
    });
  }

  showAddOp(group, op) {
    const id = 'FUN:' + group + ':' + op; 
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(add ' + group + ' op)', 'TEXT[op][readonly](' + op + ')'],
          ['HIDDEN[id](' + id + ')'],
          ['CLOSE', 'SUBMIT[add](addop)']
        ]
      });
    });
  }

  showAddOutput() {
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(add output)', 'TEXT[name]'],
          ['CLOSE', 'SUBMIT[add](addout)']
        ]
      });
    });
  }

  showCreateMod() {
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(create mod)', 'TEXT[name]'],
          ['CLOSE', 'SUBMIT[add](addmod)']
        ]
      });
    });
  }

  showDeleteMod(name) {
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(delete mod)', 'TEXT[name][readonly](' + name + ')'],
          ['CLOSE', 'SUBMIT[delete](delmod)']
        ]
      });
    });
  }

  showMeldMod(name) {
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(meld mod)', 'TEXT[name][readonly](' + name + ')'],
          ['CLOSE', 'SUBMIT[meld](meldmod)']
        ]
      });
    });
  }

  showCheckMod(name) {
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: [
          ['LABEL(check mod)', 'TEXT[name][readonly](' + name + ')'],
          ['CLOSE', 'SUBMIT[check](chkmod)']
        ]
      });
    });
  }

  showMung() {
    const mung = mungService.loadMung();
    const name = mung['name'] || '';
    this.setState({ form: undefined }, () => {
      this.setState({ 
        form: mungService.makeForm('MUNG:' + name)
      });
    });
  }

  showResetMung() {
    this.setState({ form: undefined }, () => {
      const mung = mungService.loadMung();
      const name = mung['name'] || '';
      this.setState({ 
        form: [
          ['LABEL(clear mung)', 'TEXT[old][readonly](' + name + ')'],
          ['LABEL(create mung)', 'TEXT[name]'],
          ['CLOSE', 'SUBMIT[reset](newmung)']
        ]
      });
    });
  }

  flairClick(e) {
    const val = e.target.value;
    const parts = val.split(':')
    switch (parts[0]) {
      case 'bg':
        if (parts[1] === '_add') {
          this.showAddBGFlair();
          return;
        }
        this.setState({ bg: parts[1]});
        break;
      case 'fg':
        if (parts[1] === '_add') {
          this.showAddFGFlair();
          return;
        }
        this.setState({ fg: parts[1]});
        break;
      default:
        console.error('invalid flair type: ' + parts[0]);
    }
    // TODO(greg): store flair selection for user/device.
  }

  loadBGFlair(flair) {
    const css = [];
    if (flair.bg) {
      css.push('.bg.' + flair.name + ' { background: ' + flair.bg + '; }')
    } else {
      css.push('.bg.' + flair.name + ' { background: gainsboro; }')
    }
    if (flair.track) {
      css.push('.track.' + flair.name + ' { border-color: ' + flair.track + '; }')
    }
    if (flair.arrow) {
      css.push('.arrow.' + flair.name + ' { border-color: ' + flair.arrow + ' transparent transparent ' + flair.arrow + '; }')
    } else if (flair.op) { 
      css.push('.arrow.' + flair.name + ' { border-color: ' + flair.op + ' transparent transparent ' + flair.op + '; }')
    }
    if (flair.op) {
      if (flair.op_fg) {
        css.push('.fg.' + flair.name + ' { background: ' + flair.op + '; color: ' + flair.op_fg + ' }')
      } else {
        css.push('.fg.' + flair.name + ' { background: ' + flair.op + '; }')
      }
      css.push('.fg.' + flair.name + '::after { border-color: ' + flair.op + ' transparent transparent ' + flair.op + '; }')
      css.push('.fg.' + flair.name + '::before { border-color: ' + flair.op + ' transparent transparent ' + flair.op + '; }')
    }
    $('<style>')
      .prop('type', 'text/css')
      .html(css.join(' '))
      .appendTo('head');
    const bgs = this.state.bgs;
    if (!bgs.includes(flair.name)) {
      bgs.push(flair.name)
      this.setState({ bgs: bgs })
    }
  }

  loadFGFlair(flair) {
    const css = [];
    for (let i = 0; i < flair.ops.length; i++) {
      const op = flair.ops[i];
      if (op.fg) {
        css.push('.fg.' + flair.name + '.col' + (i + 1) +' { background: ' + op.bg + '; color: ' + op.fg + '; }');
      } else {
        css.push('.fg.' + flair.name + '.col' + (i + 1) +' { background: ' + op.bg + '; color: black; }');
      }
      css.push('.fg.' + flair.name + '.col' + (i + 1) +'::after { border-color: ' + op.bg + ' transparent transparent ' + op.bg + '; }');
      css.push('.fg.' + flair.name + '.col' + (i + 1) +'::before { border-color: ' + op.bg + ' transparent transparent ' + op.bg + '; }');
    }
    $('<style>')
      .prop('type', 'text/css')
      .html(css.join(' '))
      .appendTo('head');
    const fgs = this.state.fgs;
    if (!fgs.includes(flair.name)) {
      fgs.push(flair.name)
      this.setState({ fgs: fgs })
    }
  }

  componentDidMount() {
    mungService.addCallback(() => this.forceUpdate(() => {
      this.setState({ loading: false });
    }));
    // TODO(greg): load items from somewhere.
    let flairBG = [
      { 
        name: 'argon',
        bg: 'linear-gradient(0deg, #55ddff 0%, #dd55ff 85%)',
        arrow: '#55ddff',
        op: '#55ddff'
      },
      { 
        name: 'sunset',
        bg: 'linear-gradient(0deg, #ff8149 0%, #201bff 100%)',
        track: '#940051',
        op: '#ff8149'
      },
      { 
        name: 'coder',
        bg: 'linear-gradient(0deg, #008000 0%, #000000 100%)',
        track: 'silver',
        op: '#00cc00'
      },
      { 
        name: 'none',
      },
    ];
    for (let i = 0; i < flairBG.length; i++) {
      this.loadBGFlair(flairBG[i]);
    }
    let flairFG = [
      { 
        name: 'bgyr',
        ops: [
          { bg: '#007bff' }, // blue
          { bg: '#28a745' }, // green
          { bg: '#ffc107' }, // yellow
          { bg: '#dc3545' }  // red
        ]
      },
      { 
        name: 'rare',
        ops: [
          { bg: '#0070dd' }, // blue
          { bg: '#1eff00' }, // green
          { bg: '#ff8000' },  // orange
          { bg: '#a335ee' } // purple
        ]
      },
      { 
        name: 'neon',
        ops: [
          { bg: '#00c3ff' }, // blue
          { bg: '#6aff00' }, // green
          { bg: '#fff700' }, // yellow
          { bg: '#ff00ff' }  // purple
        ]
      },
      { 
        name: 'mono',
        ops: [
          { bg: '#cccccc' },
          { bg: '#999999' },
          { bg: '#666666' },
          { bg: '#333333', fg: 'white' }
        ]
      },
    ];
    for (let i = 0; i < flairFG.length; i++) {
      this.loadFGFlair(flairFG[i]);
    }
  }

  render() {
    const form = this.state.form ? <MungletForm className='border-bottom' body={this.state.form} onSubmit={this.handleFormSubmit} onClose={this.closeForm} /> : '';
    // TODO(greg): load cards from somewhere.
    const coreCards = [
      { id: 'FUN:core:in', name: 'in', icon: 'arrow_forward_ios', desc: 'create a mung input' },
      { id: 'FUN:core:out', name: 'out', icon: 'arrow_back_ios', desc: 'create a mung output' },
    ];
    const mathCards = [
      { id: 'FUN:math:sum', name: 'sum', icon: 'add', desc: 'returns the summation of the inputs' },
      { id: 'FUN:math:prod', name: 'prod', icon: 'close', desc: 'returns the product of the inputs' },
    ];
    const mod = mungService.loadMod();
    const mung = mungService.loadMung();
    const [ row, rowEnd ] = mungService.rows();
    let mungName, modName;
    if (mod['name']) {
      mungName = (
        <div className='input-group-prepend'>
          <label className='input-group-text form-control border-info' aria-label='name'>{mung['name']}</label>
        </div>
      );
      modName = <label className='input-group-text form-control border-info text-primary text-truncate' aria-label='mod'>{mod['name']}</label>;
    } else {
      mungName = <label className='input-group-text form-control border-info' aria-label='name'>{mung['name']}</label>;
    }
    let loading;
    if (this.state.loading) {
      loading = (
        <div className='wait-screen'>
          <h2 className="center">
            <TextFlair text='thinking...' />
          </h2>
        </div>
      );
    }
    return (
      <div className='mungwrap d-flex flex-column'>
        {loading}
        <MungBoard rows={row} rowEnd={rowEnd} bg={this.state.bg} fg={this.state.fg} selected={this.state.selOp} onOpClick={this.onOpClick} />
        <div className='fixed-bottom'>
          <div className='menu-toggle text-center bg-light'>
            <div className='input-group p-1'>
              <div className='input-group-prepend'>
                <button type='button' className='btn btn-info p-0' data-toggle='collapse' data-target='#mungbar' aria-controls='mungbar' aria-expanded='false' aria-label='Toggle menu'>
                  <i className='material-icons-outlined md-36 align-middle'>drag_handle</i>
                </button>
                <label className='input-group-text font-weight-bold border-info' aria-label='type'>mung</label>
              </div>
              {mungName}
              {modName}
              <div className='input-group-append'>
                <button type='button' className='btn btn-info p-0' data-toggle='collapse' data-target='#mungbar' aria-controls='mungbar' aria-expanded='false' aria-label='Toggle menu'>
                  <i className='material-icons-outlined md-36 align-middle'>drag_handle</i>
                </button>
              </div>
            </div>
          </div>
          <div className='flex-grow-1 collapse navbar-collapse bg-light shadow-lg show' id='mungbar'>
            <MungMenu changeMod={this.changeMod} createMod={this.showCreateMod} deleteMod={this.showDeleteMod} meldMod={this.showMeldMod} checkMod={this.showCheckMod} 
              tryMung={this.showMung} resetMung={this.showResetMung}  flairBGs={this.state.bgs} flairFGs={this.state.fgs} flairClick={this.flairClick} />
            <div className='mungdeck overflow-auto'>
              {form}
              <div className='accordion' id='mungDeckAccordion'>
                <MungDeck name='core' desc='built in ops' cards={coreCards} handleCardClick={this.handleCardClick} />
                <MungDeck name='math' desc='math operations' cards={mathCards} handleCardClick={this.handleCardClick} />
                <MungDeck name='string' desc='ops for string manipulation' handleCardClick={this.handleCardClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
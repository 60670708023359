import firebase from 'firebase/app';
import 'firebase/firestore';
import { firebaseAuth, firestoreDB } from './firebase';
import { memberService } from './auth';

export const mungService = {
  addCallback,
  addInput,
  addOp,
  addOutput,
  changeMod,
  getMod,
  getMung,
  loadMod,
  loadMung,
  makeForm,
  makeMod,
  makeMung,
  parseMung,
  nixMod,
  rows,
};

const apiCallbacks = [];

function addCallback(f) {
  apiCallbacks.push(f);
}

function callback(obj) {
  for (const [, f] of apiCallbacks.entries()) {
    f(obj);
  }
}

function addFun(name, op) {
  // TODO(greg): add function def to mung.
}

function addInput(name, type,  mod) {
  return new Promise((resolve, reject) => {
    if (!name) {
      return reject(new Error('input name must be specified'));
    }
    if (!type) {
      return reject(new Error('input type must be specified'));
    }
    const mung = loadMung();
    const id = type.toUpperCase() + ':' + mung['name'] + ':' + name;
    const ins = mung['ins'] || {};
    const rows = mung['rows'] || [];
    if (ins[id]) {
      return reject(new Error('input ' + name + ' already exists'));
    }
    const row = { in: { id: id, name: name, type: type } };
    rows.push(row);
    ins[id] = rows.length;
    mung['ins'] = ins;
    mung['rows'] = rows;

    // if (mod) {
    //   const m = loadMod();
    // }

    saveMung(mung);
    resolve(mung);
  });
}

function addOp(srcs, op) {
  // TODO(greg): load op icons from somewhere.
  const icons = {
    'FUN:math:sum': 'add',
    'FUN:math:prod': 'close'
  }
  return new Promise((resolve, reject) => {
    console.error('adding op: ' + srcs + ', ' + op);
    if (!srcs) {
      return reject(new Error('srcs must be selected'));
    }
    const mung = loadMung();
    const ins = mung['ins'] || {};
    const rowIdx = [];
    for (let i = 0; i < srcs.length; i++) {
      const src = srcs[i];
      const idx = ins[src];
      if (!idx) {
        return reject(new Error('input ' + src + ' does not exist'));
      }
      rowIdx.push(idx)
    }
    const rows = mung['rows'] || [];
    let group = 0;
    let nextRow = 0;
    for (let i = 0; i < rowIdx.length; i++) {
      const row = rows[rowIdx[i] - 1] || {};
      const ig = row['in']['group'];
      if (ig > group) {
        group = ig;
      }
      const ops = row['ops'] || [];
      for (let j = 0; j < ops.length; j++) {
        const og = ops[j]['group'];
        if (og > group) {
          group = og;
        }
      }
      if (rowIdx[i] > nextRow) {
        nextRow = rowIdx[i];
      }
    }
    const ops = mung['ops'] || [];
    const opCount = mung['opCount'] || {};
    const parts = op.split(':');
    let name = parts[parts.length - 1];
    const count = opCount[name] || 0;
    opCount[name] = count + 1;
    name = name + '' + (count + 1);
    mung['opCount'] = opCount;
    ops.push(name);
    mung['ops'] = ops;
    group = group + 1;
    console.error('adding op: ' + rowIdx + ' g: ' + group);
    const colIdx = mung['nextCol'] || 1;
    for (let i = 0; i < rowIdx.length; i++) {
      // TODO(greg): get op input name and type from function def.
      const id = 'LIST' + ':' + mung['name'] + ':' + name + ':' + 'in';
      const row = rows[rowIdx[i] - 1] || {};
      const ops = row['ops'] || [];
      const icon = icons[op] || 'report';
      ops.push({ id: id, name: name, group: group, icon: icon, colIdx: colIdx });
      row['ops'] = ops;
      rows[rowIdx[i] - 1] = row;
    }
    // Add output from op as new input.
    // TODO(greg): look up outputs from function def, e.g. in the case of multiple.
    // TODO(greg): look up type for output from funcion def.
    const id = 'FLOAT' + ':' + mung['name'] + ':' + name + ':' + 'out';
    const row = { in: { id: id, name: name, type: 'float', display: '=', group: group, colIdx: colIdx } };
    rows.splice(nextRow, 0, row);
    for (let i = 0; i < rows.length; i++) {
      ins[rows[i].in.id] = i + 1;
    }
    mung['ins'] = ins;
    mung['rows'] = rows;
    // Reverse direction of colors to get 1,2,3,4,3,2,1,2...
    let colDir = mung['colDir'] || 1;
    colDir = colIdx === 4 ? -colDir : (colIdx === 1 && colDir === -1 ? -colDir : colDir);
    mung['nextCol'] = colIdx + colDir;
    mung['colDir'] = colDir;
    mung['rowEnd'] = group;
    saveMung(mung);
    resolve(mung);
  });
}

function addOutput(src, name) {
  return new Promise((resolve, reject) => {
    const mung = loadMung();
    const outs = mung['outs'] || {};
    if (outs[name]) {
      return reject(new Error('output ' + name + ' already exists'));
    }
    const ins = mung['ins'] || {};
    const rowIdx = ins[src];
    if (!rowIdx) {
      return reject(new Error('src ' + src + ' does not exist'));
    }
    const rows = mung['rows'] || [];
    const row = rows[rowIdx - 1] || {};
    if (row['out']) {
      return reject(new Error('output for ' + src + ' already exists'));
    }
    const parts = src.split(':');
    const type = parts[0];
    const id = type + ':' + mung['name'] + ':' + name;
    row['out'] = { id: id, name: name, type: type.toLowerCase() };
    outs[name] = rowIdx;
    mung['outs'] = outs;
    saveMung(mung);
    resolve(mung);
  });
}

function makeMung(name) {
  if (!name) {
    return Promise.reject(new Error('name must be specified'));
  }
  return new Promise((resolve, reject) => {
    const mung = {};
    mung['name'] = name;
    saveMung(mung);
    resolve(mung);
  });
}

async function parseMung(raw) {
  if (!raw) {
    return Promise.reject(new Error('raw must be specified'));
  }
  const mungName = raw['path'] + ':' + raw['name'];
  await mungService.makeMung(mungName);
  const ins = raw['in'];
  for (const [, i] of ins.entries()) {
    await mungService.addInput(i['name'], i['type'].toLowerCase());
  }
  const ops = raw['has'];
  // Sort ops by group then name.
  ops.sort((a, b) => (a.group > b.group) ? 1 : (a.group === b.group) ? ((a.name > b.name) ? 1 : -1) : -1);
  for (const [, o] of ops.entries()) {
    const srcs = [];
    // TODO(greg): correctly handle one-to-many and many-to-many relationships.
    // TODO(greg): handle optional and constant inputs.
    for (const [, i] of o['in'].entries()) {
      for (const [, u] of i['use'].entries()) {
        srcs.push(u['id']);
      }
    }
    const fun = o['use'][0]['id'];
    await mungService.addOp(srcs, fun);
  }
  const outs = raw['out'];
  for (const [, o] of outs.entries()) {
    const src = o['use'][0]['id'];
    await mungService.addOutput(src, o['name']);
  }
  // TODO(greg): remove sessionStorage after parsing is working
  sessionStorage.setItem('mung_raw', JSON.stringify(raw));
  const funs = raw['use'];
  sessionStorage.setItem('mung_funs', JSON.stringify(funs));
  return Promise.resolve();
}

function loadMod() {
  const js = sessionStorage.getItem('mod');
  if (!js || js === 'undefined') {
    return {};
  }
  return JSON.parse(js);
}

function loadMung() {
  const mung = JSON.parse(localStorage.getItem('mung')) || null;
  if (!mung) {
    return {};
  }
  return mung;
}

function changeMod(name) {
  if (!name) {
    return Promise.reject(new Error('mod name must be specified'));
  }
  return new Promise((resolve, reject) => {
    // Get member profile from database.
    const user = firebaseAuth.currentUser;
    if (!user) {
      return reject(new Error('no current member'))
    }
    const dr = firestoreDB.collection('member').doc(user.uid);
    dr.get()
      .then((doc) => {
        if (doc.exists) {
          dr.update({
            selmod: name,
          }).then(() => {
              // Update selected in local copy.
              const member = doc.data();
              member['selmod'] = name;
              memberService.storeMember(member);
            }, err => reject(new Error('updating member: ' + err)))
            .then(() => getMod(name))
            .then(mod => mungService.getMung(mod['roots'][0], mod['name']),
              err => reject(new Error('getting mod: ' + err)))
            .then(resolve, err => reject(new Error('getting mung: ' + err)));
        } else {
          return reject(new Error('member does not exist'));
        }
      }, (err) => reject(new Error('getting member: ' + err)))
      .catch((err) => reject(new Error('updating member: ' + err)));
  });
}

function getMod(name) {
  if (!name) {
    return Promise.reject(new Error('mod name must be specified'));
  }
  const realm = 'dev';
  const req = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      r: realm,
      m: name,
    })
  };
  return new Promise((resolve, reject) => {
    fetch(`/mod`, req)
      .then(response => response.json())
      .then(mod => {
        saveMod(mod);
        resolve(mod);
      })
      .catch(error => reject(new Error('getting mod: ' + error)));
    });
}

function getMung(name, mod) {
  if (!name) {
    return Promise.reject(new Error('mung name must be specified'));
  }
  const realm = 'dev';
  let body = {
    r: realm,
    n: name,
  };
  if (mod) {
    body['m'] = mod;
  }
  const req = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  return new Promise((resolve, reject) => {
    fetch(`/obj`, req)
      .then(response => response.json())
      .then(mung => parseMung(mung), err => reject(new Error('getting mung: ' + err)))
      .then(resolve, err => reject(new Error('parsing mung: ' + err)));
    });
}

function makeMod(name) {
  if (!name) {
    return Promise.reject(new Error('mod name must be specified'));
  }
  const realm = 'dev';
  const req = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      r: realm,
      m: name,
    })
  };
  return new Promise((resolve, reject) => {
    fetch(`/mod/make`, req)
      .then(() => {
        // Get member profile from database.
        const user = firebaseAuth.currentUser;
        const dr = firestoreDB.collection('member').doc(user.uid);
        dr.get()
          .then((doc) => {
            if (doc.exists) {
              dr.update({
                mods: firebase.firestore.FieldValue.arrayUnion(name),
                selmod: name,
              }).then(() => {
                  // Update mods and selected in local copy.
                  const member = doc.data();
                  const mods = member['mods'] || [];
                  mods.push(name);
                  member['mods'] = mods;
                  member['selmod'] = name;
                  memberService.storeMember(member);
                  return resolve(member)
                }, (err) => {
                  return reject(new Error('setting member: ' + err));
                });
            } else {
              return reject(new Error('member does not exist'));
            }
          }, (err) => reject(new Error('getting member: ' + err)))
          .catch((err) => reject(new Error('updating member: ' + err)));
      }, (err) => reject(new Error('making mod: ' + err)));
    });
}

function nixMod(name) {
  if (!name) {
    return Promise.reject(new Error('mod name must be specified'));
  }
  const realm = 'dev';
  const req = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      r: realm,
      m: name,
    })
  };
  return new Promise((resolve, reject) => {
    fetch(`/mod/nix`, req)
      .then(() => {
        // Get member profile from database.
        const user = firebaseAuth.currentUser;
        const dr = firestoreDB.collection('member').doc(user.uid);
        dr.get()
          .then((doc) => {
            if (doc.exists) {
              dr.update({
                mods: firebase.firestore.FieldValue.arrayRemove(name)
              }).then(() => {
                  // Remove mod from local copy.
                  const member = doc.data();
                  const mods = member['mods'] || [];
                  const index = mods.indexOf(name);
                  if (index > -1) {
                    mods.splice(index, 1);
                  }
                  member['mods'] = mods;
                  memberService.storeMember(member);
                  return resolve(member)
                }, (err) => {
                  return reject(new Error('setting member: ' + err));
                });
            } else {
              return reject(new Error('member does not exist'));
            }
          }, (err) => {
            return reject(new Error('getting member: ' + err));
          })
          .catch((err) => reject(new Error('updating member: ' + err)));
      }, (err) => reject(new Error('nixing mod: ' + err)));
    });
}

function saveMod(mod) {
  if (!mod) {
    return;
  }
  sessionStorage.setItem('mod', JSON.stringify(mod));
  callback(mod);
}

// merge ops into single block when next to eachother
// object oriented like programming
// unit testing/executing - show passes through, visualize flow of data through ops
// I/O and testing with "Billy"

function saveMung(mung) {
  // TODO(greg): expose parameter for when to callback (e.g. only on last call)
  if (!mung) {
    return;
  }
  localStorage.setItem('mung', JSON.stringify(mung));
  callback(mung);
}

function sync() {
  // TODO(greg): synchronize local copy with cloud.
}

function rows() {
  const mung = loadMung();
  const row = mung['rows'];
  const rowEnd = mung['rowEnd'];
  // TODO(greg): colors should also be determined automatically, not on the row/ops.
  // const row = [
  //   // { in: "a", ops: [{ name: "sum", group: 1, icon: "add" }]},
  //   { in: { name: "a", badge: 0 } },
  //   { in: { name: "b", badge: 2 }, ops: [
  //       { name: "sum", group: 1, badge: 0, icon: "add", colIdx: 1 }
  //     ]},
  //   { in: { name: "s1", badge: 4 }, ops:[
  //       { name: "sum", group: 2, badge: 1, icon: "add", colIdx: 2 }
  //     ], colIdx: 1, out: { name: "a+b", badge: 2 }, start: 1},
  //   { in: { name: "c", badge: 0 }, ops: [
  //       { name: "sum", group: 1, badge: 0, icon: "add", colIdx: 3 },
  //       { name: "sum", group: 2, badge: 3, icon: "add", colIdx: 2 }
  //     ]},
  //   { in: { name: "s2", badge: 1 }, out: { name: "a+b+c", badge: 0 }, ops:[
  //       { name: "sum", group: 3, badge: 2, colIdx: 4 }
  //     ], colIdx: 2, start: 2 },
  //   { in: { name: "d", badge: 0 }, ops: [
  //       { name: "sum", group: 1, badge: 0, icon: "add", colIdx: 3 },
  //       { name: "sum", group: 3, badge: 1, colIdx: 4 }
  //     ]},
  //   { in: { name: "s3", badge: 1 }, out: { name: "c+d", badge: 2 }, ops: [
  //       { name: "prod", group: 4, badge: 9, icon: "close", colIdx: 1 },
  //     ], colIdx: 3, start: 1 },
  //   { in: { name: "s4", badge: 6 }, out: { name: "a+b+c+d", badge: 2 }, ops: [
  //       { name: "prod", group: 4, badge: 3, icon: "close", colIdx: 1 },
  //     ], colIdx: 4, start: 3 },
  //   { in: { name: "p1", badge: 0 }, out: { name: "(a+b+c+d)*(c+d)", badge: 2 }, colIdx: 1, start: 4 },
  //   { in: { name: "e", badge: 2 }, ops: [
  //       { name: "sum", group: 2, badge: 0, icon: "add", colIdx: 2 }
  //     ]},
  //   { in: { name: "f", badge: 2 }, ops: [
  //       { name: "sum", group: 2, badge: 0, icon: "add", colIdx: 2 }
  //     ]},
  //   { in: { name: "s5", badge: 4 }, out: { name: "e+f", badge: 2 }, colIdx: 2, start: 2},
  //   { in: { name: "g", badge: 7 }, ops: [
  //       { name: "sum", group: 3, badge: 0, icon: "add", colIdx: 3 }
  //     ]},
  //   { in: { name: "h", badge: 1 }, ops: [
  //       { name: "sum", group: 3, badge: 3, icon: "add", colIdx: 3 }
  //     ]},
  //   { in: { name: "s6", badge: 0 }, out: { name: "g+h", badge: 2 }, colIdx: 3, start: 3},
  // ];
  // const rowEnd = 5;
  return [row, rowEnd];
}

const minFormRow = 2;
const maxFormRpw = 4;
function makeForm(target) {
  const mung = loadMung();
  const rows = mung['rows'] || [];
  if (rows.length === 0) {
    return [
      ['TEXTAREA[error][readonly](Error: no mung specified)'],
      ['CLOSE'],
    ]
  }
  const form = [];
  const types = {};
  let type;
  let names = [];
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const input = row['in'];
    const group = input['group'] || 0;
    const name = input['name'];
    if (group === 0) {
      const it = inputType(input['type']);
      types[name] = it;
      if (!type) {
        type = it;
      }
      if (type === 'list' || it === 'list' || (it !== type && names.length >= minFormRow) || names.length === maxFormRpw) {
        const frow = [];
        for (const [, n] of names.entries()) {
          frow.push(types[n].toUpperCase() + '[' + n + ']');
        }
        form.push(frow);
        type = it;
        names = [];
      }
      names.push(name);
    } 
  }
  if (names) {
    const frow = [];
    for (const [, n] of names.entries()) {
      frow.push(types[n].toUpperCase() + '[' + n + ']');
    }
    form.push(frow);
  }
  form.push(['CLOSE', 'SUBMIT[call](' + target + ')']);
  return form;
}

function inputType(type) {
  switch (type) {
    case 'bool':
      return 'check';
    case 'float':
    case 'int':
      return 'number';
    case 'list':
      return 'list';
    case 'text':
      return 'text';
    default:
      throw new Error('unsupported input type ' + type)
  }
}

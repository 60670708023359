import React from 'react';
import { memberService } from '../common/auth';
import { firebaseAuth, firebaseUIConfig } from '../common/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import TextFlair from '../common/TextFlair';

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      firebaseConfig: firebaseUIConfig
    };
    const signInSuccess = this.state.firebaseConfig.callbacks.signInSuccessWithAuthResult;
    this.state.firebaseConfig.callbacks.signInSuccessWithAuthResult = (authResult, redirectUrl) => {
      this.setState({ loading: true });
      return signInSuccess(authResult, redirectUrl);
    }
    this.state.firebaseConfig.callbacks.signInSuccessWithAuthResult.bind(this);
    memberService.logout();
  }

  render() {
    let display;
    if (this.state.loading) {
      display = (
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary m-5" style={{'width': '3rem', 'height': '3rem'}} role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      display = (
        <div className="login">
          <div className="text-center mt-4">
            <TextFlair text='coding for everyone™' />
          </div>
          <StyledFirebaseAuth uiCallback={ui => ui.disableAutoSignIn()} uiConfig={this.state.firebaseConfig} firebaseAuth={firebaseAuth} />
        </div>
      );
    }
    return (
      <div>
        <div className="card shadow mt-3 ml-2 mr-2 text-center">
          {display}
        </div>
      </div>
    );
  }
}

import React from 'react';
import LessonDeck from './LessonDeck';

export default class UnitCard extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.props.onClick.bind(this);
    this.onLessonClick = this.props.onLessonClick.bind(this);
  }

  render() {
    // TODO(greg): use len(lessons) below instead of passing count.
    // TODO(greg): show pre-reqs on locked cards?
    const target = "collapse" + this.props.id;
    let col = "warning";
    if (this.props.lessons.length === this.props.done) {
      col = "success";
    }
    if (this.props.locked) {
      return (
        <div className="unitcard m-1">
          <label className="btn btn-danger pt-4 pb-4 w-100 disabled">
            <h4 className="mb-0">{this.props.title}</h4>
            <span class="unit count badge badge-light">
              0 of {this.props.lessons.length}
            </span>
          </label>
        </div>
      );
    }
    return (
      <div className="unitcard m-1">
        <label className={"btn btn-" + col + " pt-4 pb-4 w-100"} data-toggle="collapse" data-target={"#"+target} aria-expanded="true" aria-controls={target}>
          <input className="d-none" type="radio" name="unitcard" value={this.props.id} autoComplete="off" onClick={this.onClick} />
          <h4 className="mb-0">{this.props.title}</h4>
          <span class="unit count badge badge-light">
            {this.props.done ? this.props.done : 0} of {this.props.lessons.length}
          </span>
          <div id={target} class="collapse" data-parent="#unitdeck">
            <span>
              {this.props.desc}
            </span>
            <div>
              <LessonDeck lessons={this.props.lessons} onClick={this.onLessonClick} />
            </div>
          </div>
        </label>
      </div>
    );
  }

}
  
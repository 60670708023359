import React from 'react';

export default class MungFlair extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.props.onClick.bind(this);
  }

  render() {
    const bgs = [];
    for (let i = 0; i < this.props.bgs.length; i++) {
      const bg = this.props.bgs[i]
      const cn = 'flair-pick bg btn ' + bg
      bgs.push(
        <label className={cn} data-toggle="button" aria-pressed="false" autoComplete="off">
          <input className="d-none" type="radio" name="mungcard" value={"bg:" + bg} autoComplete="off" onClick={this.onClick} />
        </label>
      );
    }
    bgs.push(
      <label className='flair-pick bg btn' data-toggle="button" aria-pressed="false" autoComplete="off">
        <input className="d-none" type="radio" name="mungcard" value={"bg:_add"} autoComplete="off" onClick={this.onClick} />
        <span className="text-muted align-middle">+</span>
      </label>
    );
    const fgs = [];
    for (let i = 0; i < this.props.fgs.length; i++) {
      const fg = this.props.fgs[i]
      const cn = 'flair-pick fg btn ' + fg
      fgs.push(
        <label className={cn} data-toggle="button" aria-pressed="false" autoComplete="off">
          <input className="d-none" type="radio" value={"fg:" + fg} autoComplete="off" onClick={this.onClick} />
          <div className={'flair-pick-part fg col1 ' + fg}></div>
          <div className={'flair-pick-part fg col2 ' + fg}></div>
          <div className={'flair-pick-part fg col3 ' + fg}></div>
          <div className={'flair-pick-part fg col4 ' + fg}></div>
        </label>
      );
    }
    fgs.push(
      <label className='flair-pick fg btn' data-toggle="button" aria-pressed="false" autoComplete="off">
        <input className="d-none" type="radio" value={"fg:_add"} autoComplete="off" onClick={this.onClick} />
        <span className="text-muted align-middle">+</span>
      </label>
    );
    return (
      <div id="mungFlair" class="alert alert-secondary alert-dismissible fade collapse mb-0 mt-0" role="alert">
        <div className="flair-group">
          {bgs}
        </div>
        <div className="flair-group">
          {fgs}
        </div>
        <button type="button" class="close" data-toggle="collapse" data-target="#mungFlair" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}
  
import React from 'react';
import $ from 'jquery';

export default class TextFlair extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flairCol: '',
      flairDur: ''
    };
  }
  
  flair(txt, col, dur) {
    const items = [];
    let count = 0;
    for (let i = 0; i < txt.length; i++) {
      if (txt[i] === ' ') {
        items.push(<span> </span>);
        continue;
      }
      let cn = 'flair ' + col + dur + 'delay' + (count % 9 + 1);
      items.push(<span className={cn}>{txt[i]}</span>);
      count++;
    }
    return items;
  }

  flairColor(col1, col2) {
    const css = [];
    const suffix = col1.replace('#', '') + col2.replace('#', '');
    const name = 'flairAnim' + suffix;
    css.push('.flair.col' + suffix + ' { -webkit-animation-name: ' + name + '; animation-name: ' + name + '; }');
    css.push('@-webkit-keyframes ' + name + ' { 0% { color: ' + col1 + '; } 50% { color: ' + col2 + ';} 100% { color: ' + col1 + '; } }');
    css.push('@keyframes ' + name + ' { 0% { color: ' + col1 + '; } 50% { color: ' + col2 + ';} 100% { color: ' + col1 + '; } }');
    $('<style>')
      .prop('type', 'text/css')
      .html(css.join(' '))
      .appendTo('head');
    this.setState({ flairCol: 'col' + suffix + ' ' });
  }

  flairDuration(dur) {
    const css = [];
    css.push('.flair.dur' + dur + ' { -webkit-animation-duration: ' + dur + '; animation-duration: ' + dur + '; }');
    $('<style>')
      .prop('type', 'text/css')
      .html(css.join(' '))
      .appendTo('head');
    this.setState({ flairDur: 'dur' + dur + ' ' });
  }

  componentDidMount() {
    if (this.props.col1 && this.props.col2) {
      this.flairColor(this.props.col1, this.props.col2);
    }
    if (this.props.dur) {
      this.flairDuration(this.props.dur);
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <span className='sr-only'>{this.props.text}</span>
        {this.flair(this.props.text, this.state.flairCol, this.state.flairDur)}
      </div>
    );
  }
}
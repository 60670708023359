import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { memberService } from '../common/auth';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    memberService.hasAuth()
    ? <Component {...props} />
    : <Redirect to={{ pathname: '/login' }} />
  )} />
)

export default PrivateRoute;
import React from 'react';
import MungOp from './MungOp';
import $ from 'jquery';

export default class MungRow extends React.Component {
  constructor(props) {
    super(props);
    this.onOpBadgeClick = this.onOpBadgeClick.bind(this);
    this.onOpClick = this.onOpClick.bind(this);
    this.onClick = this.props.onOpClick.bind(this);
    this.hideBadges = this.props.hideBadges.bind(this);
    this.showBadges = this.props.showBadges.bind(this);
  }

  onOpBadgeClick(e) {
    console.error('op badge clicked: ' + e.target.value)
    if (this.props.selected !== undefined) {
      const info = $('#' + this.props.in.name + "Info");
      info.collapse('toggle')
    }
  }

  onOpClick(e) {
    console.error('op row clicked: ' + this.props.in.name)
    if (this.props.selected === undefined) {
      const info = $('#' + this.props.in.name + "Info");
      info.collapse('hide')
    }
    this.onClick(this.props.in.name, e);
  }

  trackElem(end) {
    let cn = 'track ' + this.props.bg;
    let arrow = 'arrow ' + this.props.bg;
    if (end) {
      cn = cn + ' end'
      arrow = arrow + ' d-none'  
    }
    return (
      <td className="header-r">
        <div className={cn}>
          <div className="arrowAnim">
            <div className="arrowSliding">
              <div className={arrow}></div>
            </div>
          </div>
        </div>
      </td>
    );
  }

  render() {
    const ops = this.props.ops === undefined ? [] : this.props.ops;
    const opGroup = {};
    for (const [, op] of ops.entries()) {
      opGroup[op.group] = op
    }
    console.info('mungrow: ' + JSON.stringify(opGroup))
    const prepend = [];
    for (let i = 0; i < this.props.start; i++) {
      prepend.push(<MungOp type='' />)
    }
    const items = [];
    const tracks = [];
    let opCount = ops.length;
    let flair = this.props.bg;
    if (prepend.length > 0) {
      flair = this.props.fg;
    }
    items.push(
      <MungOp id={this.props.in.id} type='in' name={this.props.in.name} display={this.props.in.display} group={this.props.in.group} 
        icon={this.props.in.icon} flair={flair} badge={this.props.in.badge} valType={this.props.in.type}
        selected={this.props.selected === this.props.in.id} colIdx={this.props.in.colIdx} onClick={this.onOpClick}
        hideBadges={this.hideBadges} showBadges={this.showBadges} onBadgeClick={this.onOpBadgeClick}
        showBadge={this.props.showBadge} />
      );
    tracks.push(this.trackElem())
    for (let i = this.props.start + 1; i <= this.props.end; i++) {
      if (!(i in opGroup)) {
        if (opCount > 0 || this.props.out) {
          tracks.push(this.trackElem())
        }
        items.push(<MungOp type='' />)
        continue
      }
      const op = opGroup[i]
      if ((opCount === 1) && (this.props.out === undefined)) {
        tracks.push(this.trackElem(true))
      } else if (opCount > 0 || this.props.out) {
        tracks.push(this.trackElem())
      }
      items.push(
        <MungOp id={op.id} type='op' name={op.name} group={op.group} badge={op.badge}
          selected={this.props.selected === op.id} colIdx={op.colIdx} flair={this.props.fg} icon={op.icon}
          onClick={this.onOpClick} hideBadges={this.hideBadges} showBadges={this.showBadges}
          onBadgeClick={this.onOpBadgeClick} showBadge={this.props.showBadge} />);
      opCount--;
    }
    if (this.props.out) {
      tracks.push(this.trackElem(true))
      items.push(
        <MungOp id={this.props.out.id} type='out' name={this.props.out.name} valType={this.props.out.type} group={this.props.end}
          badge={this.props.out.badge} selected={this.props.selected === this.props.out.id} flair={this.props.bg} onClick={this.onOpClick}
          hideBadges={this.hideBadges} showBadges={this.showBadges} onBadgeClick={this.onOpBadgeClick}
          showBadge={this.props.showBadge} />);
    }
    return (
      <div>
        <tr className="mungrow">
          <div className="d-flex flex-column">
            <div className="d-flex">
              {prepend}
              <div className="trackwrap">
                {tracks}
              </div>
              {items}
            </div>
            <td className="header-l">
              <div id={this.props.in.name + "Info"} class="alert alert-secondary alert-dismissible fade collapse mb-1" role="alert">
                <strong>Holy guacamole, you selected {this.props.selected} on {this.props.in.name}!</strong>
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                <button type="button" class="close" data-toggle="collapse" data-target={'#' + this.props.in.name + "Info"} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </td>
          </div>
        </tr>
      </div>
    )
  }
}

import React from 'react';
import MungCard from './MungCard';
import $ from 'jquery';

export default class MungDeck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      isLoading: false,
      selected: '',
    };
    this.onClick = this.onClick.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.handleCardClick = this.props.handleCardClick.bind(this);
  }

  onClick() {
    // Use a check to maintain the state of the toggle button.
    const btn = $('#' + this.props.name + "Button");
    const chk = $('#' + this.props.name + "Check");
    if (chk.attr('checked')) {
      btn.removeClass("active");
      btn.attr('aria-pressed', false)
      chk.attr('checked', false)
    } else {
      btn.addClass("active");
      btn.attr('aria-pressed', true)
      chk.attr('checked', true)
    }
  }

  onCardClick(e) {
    const info = $('#' + this.props.name + "Info");
    info.collapse('show')
    this.setState({ selected: e.target.value });
    // TODO(greg): send clicked card up.
    this.handleCardClick(e.target.value)
  }

  componentDidMount() {
    this.setState({ isLoading: true });
  }

  render() {
    var cards = this.props.cards
    // TODO(greg): load cards from somewhere.
    if (!cards) {
      cards = [
        { name: "test1", icon: "build" },
        { name: "test2", icon: "hardware" },
        { name: "test3", icon: "brush" },
        { name: "test4", icon: "wysiwyg" },
        { name: "test5", icon: "wysiwyg" },
        { name: "test6", icon: "wysiwyg" },
        { name: "test7", icon: "wysiwyg" },
        { name: "test8", icon: "wysiwyg" },
        { name: "test9", icon: "wysiwyg" },
        { name: "test10", icon: "wysiwyg" },
        { name: "test11", icon: "wysiwyg" },
        { name: "test12", icon: "wysiwyg" },
        { name: "test13", icon: "wysiwyg" },
        { name: "test14", icon: "wysiwyg" },
      ]
    }
    if (!cards) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    console.info('mungdeck: ' + JSON.stringify(cards))
    const items = [];
    let selectedCard;
    for (const [i, c] of cards.entries()) {
      items.push(
        <MungCard key={i} id={c.id} name={c.name} icon={c.icon} title={c.title} onClick={this.onCardClick} />
      )
      // TODO(greg): use actual descriptions.
      if (!c.desc) {
        c.desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      }
      if (c.id === this.state.selected) {
        selectedCard = c;
      }
    }
    let info;
    if (selectedCard) {
      info = (
        <h5>
          {selectedCard.name}
          <small className="text-muted pl-2">{selectedCard.desc}</small>
        </h5>
      );
    }
    return (
      <div className="card">
        <div className="card-header" id={this.props.name + "Heading"}>
          <div className="input-group mb-0">
            <div className="input-group-prepend">
              <button className="btn btn-outline-primary" type="button" id={this.props.name + "Button"} data-toggle="collapse" data-target={"#" + this.props.name + "Deck"} aria-expanded="false" aria-controls={this.props.name + "Deck"} onClick={this.onClick}>
                {this.props.name}
                <input className="d-none" type="checkbox" id={this.props.name + "Check"} />
              </button>
            </div>
            <span className="input-group-text form-control" aria-label={this.props.desc} readOnly>{this.props.desc}</span>
          </div>
        </div>
        <div id={this.props.name + "Deck"} className="collapse" aria-labelledby={this.props.name + "Heading"}> {/* data-parent="#mungDeckAccordion" */}
          <div className="mungcards btn-group-toggle p-1" data-toggle="buttons">
            {items}
          </div>
          <div id={this.props.name + "Info"} className="card-body collapse border-top" >
            {info}
          </div>
        </div>
      </div>
    )
  }
}
